<template>
    <div class="home" id="home" name="home" v-loading="loading">
        <!-- 轮播图 -->
        <div class="banner-box">
            <el-carousel :height="styleHeight">
                <el-carousel-item v-for="item in carousel" :key="item.carousel_id">
                    <!-- <img style="width: 100%; height:690px;" :src="$target + item.image" :alt="item.describes" /> -->
                    <el-image class="banner-img" :src="$target + item.image" @click="jumpBanner(item)"></el-image>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 轮播图END -->
        <div class="main-box">
            <div class="main">
                <!-- 产品中心展示区域 -->
                <div class="phone">
                    <div class="box-hd">
                        <div class="title s" @click="jumpcanpin" style="font-weight: bold;">产品中心</div>
                    </div>
                    <div class="box-bd" v-for="(item) in categoryList" :key="item.id">
                        <div class="promo-title s" @click="jumpErji(item)" :style="'background:' + item.color + ';'">
                            {{ item.name }}
                        </div>
                        <div class="list" :style="'border-top-color:' + item.color + ';'">
                            <MyList :list="item.cate" :isMore="true"></MyList>
                        </div>
                    </div>
                </div>
                <!-- 产品中心展示区域END -->
                <!-- 设计加工展示区域 -->
                <a id="shejijiagong"></a>
                <!-- ======================================================== 设计加工 ======================================================== -->
                <div class="appliance">
                    <div class="box-hd1">
                        <div class="title s" style="font-weight: bold;">设计加工</div>
                        <!-- <div class="more" id="more">
                            <MyMenu :val="2" @fromChild="getChildMsg">
                                <span slot="1">热门</span>
                                <span slot="2">电视影音</span>
                            </MyMenu>
                        </div> -->
                    </div>
                    <div class="box-bd1">
                        <div class="promo-list">
                            <ul>
                                <li @click="jumpSjInfo(item.id)" v-for="item in designRecommend" :key="item.id">
                                    <!-- <img :src="$target + item.image" /> -->
                                    <el-image class="liImage s" :src="$target + item.image" fit="contain"></el-image>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- ======================================================== 新品展示 ======================================================== -->
                <div style="background-color: #fff;">
                    <div class="appliance">
                        <div class="box-hd1">
                            <div class="title" style="font-weight: bold;">新品展示</div>
                        </div>
                        <div class="box-bd2">
                            <div class="promo-list">
                                <ul>
                                    <li v-for="item in productList" :key="item.id" @click="gotourl(item)">
                                        <!-- <img :src="$target + item.images" /> -->
                                        <el-image class="liImage s" :src="$target + item.images"
                                            fit="contain"></el-image>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <a id="guangxuezhongxin"></a>
                <div class="appliance">
                    <div class="box-hd1">
                        <div class="title" style="font-weight: bold;">光学中心</div>
                    </div>
                    <div class="box-bd1">
                        <el-carousel :height="styleHeight1">
                            <el-carousel-item v-for="item in programmeList" type="card" :key="item.carousel_id">
                                <el-image style="width: 100%;height: auto" @click="jumpCase(item)"
                                    :src="$target + item.image" fit="contain" :alt="item.describes"></el-image>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    bannerApi, categoryApi, designApi, productApi, programme_lunboApi
} from "../api/index.js";
export default {
    data() {
        return {
            loading: false,
            carousel: [], // 轮播图数据
            categoryList: [],//产品中心
            colors: ['#8E0F37', '#6E63B7', '#EC9B04', '#05B4AE'],
            designRecommend: [], //设计加工
            productList: [], //新品推荐
            programmeList: [], //案例分析
            phoneList: [], // 手机商品列表
            miTvList: "", // 小米电视商品列表
            applianceList: "", // 家电商品列表
            applianceHotList: "", //热门家电商品列表
            accessoryList: "", //配件商品列表
            accessoryHotList: "", //热门配件商品列表
            protectingShellList: "", // 保护套商品列表
            chargerList: "", //充电器商品列表
            applianceActive: 1, // 家电当前选中的商品分类
            accessoryActive: 1, // 配件当前选中的商品分类
            styleHeight: '',
            styleHeight1: '',
            token: ''
        };
    },
    watch: {

        // 家电当前选中的商品分类，响应不同的商品数据
        applianceActive: function (val) {
            // 页面初始化的时候把applianceHotList(热门家电商品列表)直接赋值给applianceList(家电商品列表)
            // 所以在切换商品列表时判断applianceHotList是否为空,为空则是第一次切换,把applianceList赋值给applianceHotList
            if (this.applianceHotList == "") {
                this.applianceHotList = this.applianceList;
            }
            if (val == 1) {
                // 1为热门商品
                this.applianceList = this.applianceHotList;
                return;
            }
            if (val == 2) {
                // 2为电视商品
                this.applianceList = this.miTvList;
                return;
            }
        },
        accessoryActive: function (val) {
            // 页面初始化的时候把accessoryHotList(热门配件商品列表)直接赋值给accessoryList(配件商品列表)
            // 所以在切换商品列表时判断accessoryHotList是否为空,为空则是第一次切换,把accessoryList赋值给accessoryHotList
            if (this.accessoryHotList == "") {
                this.accessoryHotList = this.accessoryList;
            }
            if (val == 1) {
                // 1为热门商品
                this.accessoryList = this.accessoryHotList;
                return;
            }
            if (val == 2) {
                // 2为保护套商品
                this.accessoryList = this.protectingShellList;
                return;
            }
            if (val == 3) {
                //3 为充电器商品
                this.accessoryList = this.chargerList;
                return;
            }
        },
    },
    activated() {
        // console.log('监听搜索条件',this.$route);
        // if ( this.$route.query.token != undefined) {
        //     this.token = this.$route.query.token
        //     localStorage.setItem('token',this.token)
        // }
        this.loading = true
        this.setCarouselHeight(); // 初始化时设置轮播图高度
        window.addEventListener("resize", this.setCarouselHeight); // 监听窗口大小变化
        // 获取轮播图数据
        this.getBanner()
        // 获取各类商品数据
        this.getCategory()
        // 设计加工
        this.getDesignRecommend()
        // 新品推荐
        this.getProduct()
        // 案例分析
        this.getProgramme()
    },
    methods: {
        // 轮播图跳转
        jumpBanner(e) {
            console.log(e, '123123132112132123123');
            if (e.url) {

                window.location.href = e.url;
            }
        },
        setCarouselHeight() {
            const windowWidth = window.innerWidth;
            // const aspectRatio = 8 / 2.8; // 宽高比
            if (windowWidth >= 1920) {
                this.styleHeight = 500 / 19 + 'vw'
                this.styleHeight1 = 800 / 19 + 'vw'
            } else if (windowWidth <= 750) {
                this.styleHeight = 491 / 1920 * windowWidth + 'px'
                this.styleHeight1 = 800 / 1920 * windowWidth + 'px'
            } else {
                this.styleHeight = 500 / 1920 * windowWidth / 16 + 'vw'
                this.styleHeight1 = 800 / 1920 * windowWidth / 16 + 'vw'
            }


        },
        // 设计加工详情
        jumpSjInfo() {
            // this.$router.push({ path: '/designInfo', query: { id: id }})
            this.$router.push({ path: '/case', query: { type: 1, typeName: '设计加工' } })
        },
        // 跳转光学中心
        jumpCase(e) {
            console.log(e);
            // 光学中心详情
            this.$router.push({ path: '/caseInfo', query: { type: 1, idx: e.type_id, id: e.id, typeName: '光学中心' } })
        },
        // 跳转产品中心
        jumpErji(e) {
            console.log(123);
            this.$router.push({ path: '/productContectChilds', query: { pid: e.id, name: e.name } })
        },
        jumpcanpin() {
            this.$router.push('/productContect')
        },
        // 轮播图
        getBanner() {
            let data = {
                type: 1
            }
            bannerApi(data).then((res) => {
                this.carousel = res.data;
            }).catch((res) => {
                this.$message.error(res.message)
            })
        },
        // 产品分类
        getCategory() {
            let data = {
                pid: 0,
                limit: 5
            }
            categoryApi(data).then((res) => {
                this.loading = false
                this.categoryList = res.data;
            }).catch((res) => {
                this.$message.error(res.message)
            })
        },
        // 设计加工
        getDesignRecommend() {
            let data = {
                page: 1,
                limit: 4
            }
            designApi(data).then((res) => {
                this.loading = false
                this.designRecommend = res.data.data;
            }).catch((res) => {
                this.$message.error(res.message)
            })
        },
        // 新品推荐跳转
        gotourl(item) {
            window.location.href = item.url;
        },
        // 新品推荐
        getProduct() {
            let data = {
                page: 1,
                limit: 3
            }
            productApi(data).then((res) => {
                this.loading = false
                this.productList = res.data.data;
            }).catch((res) => {
                this.$message.error(res.message)
            })
        },
        // 案例分析
        getProgramme() {
            programme_lunboApi().then((res) => {
                this.loading = false
                this.programmeList = res.data;
            }).catch((res) => {
                this.$message.error(res.message)
            })
        },
        // 获取家电模块子组件传过来的数据
        getChildMsg(val) {
            this.applianceActive = val;
        },
        // 获取配件模块子组件传过来的数据
        getChildMsg2(val) {
            this.accessoryActive = val;
        },
        // 获取各类商品数据方法封装
        getPromo(categoryName, val, api) {
            api = api != undefined ? api : "/api/product/getPromoProduct";
            this.$axios
                .post(api, {
                    categoryName
                })
                .then(res => {
                    this[val] = res.data.Product;
                })
                .catch(err => {
                    return Promise.reject(err);
                });
        }
    }
};
</script>
<style scoped>
@import "../assets/css/index.css";

/* .banner-box{
    margin: 0 auto;
    max-width: 1920px;
} */
.main-box {
    padding-bottom: 100px;
}

.banner-img {
    width: 100%;
    height: inherit;
}

.box-hd1 {
    /* height: 60px; */
    padding: 10px 0px;
    box-sizing: border-box;
    padding-bottom: 20px;

}

.box-hd1 .title {
    font-family: "惠普字体Medium";
    font-weight: bold;
    padding-left: 30px;
    font-size: 36px;
    font-weight: 200;
    line-height: 58px;
    color: #333;
    position: relative;
}

.box-hd1 .title::after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '';
    display: block;
    width: 6px;
    height: 41px;
    background: #CE3720;
    border-radius: 3px 3px 3px 3px;
}

.box-bd1 ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2%;
}

.box-bd1 ul li {
    /* margin-bottom: 87px; */
    width: 49%;
    height: 468px;
    line-height: 468px;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 20px;
}

.box-bd1 ul li .liImage:hover {
    z-index: 2;
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-transform: scale(1.05) translate(-48%, -48%);
    transform: scale(1.05) translate(-48%, -48%);
}

.box-bd1 ul li .liImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
    width: 100%;
    height: 100%;
}

.new-project {
    position: absolute;
    left: 0;
    /* top: 300px; */
    width: 100%;
    height: 300px;
    background-color: #fff;
}

.box-bd2 {}

.box-bd2 .promo-list {
    padding: 0 0;
    box-sizing: border-box;
    /* margin-top: 44px; */
    /* margin-bottom: 67px; */
    /* padding-bottom: 96px; */
}

.box-bd2 .promo-list ul {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.box-bd2 .promo-list ul li {
    position: relative;
    overflow: hidden;
    width: 32%;
    height: 450px;
}

.box-bd2 .promo-list ul li .liImage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
    width: 100%;
}

.box-bd2 .promo-list ul li .liImage:hover {
    z-index: 2;
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-transform: scale(1.05) translate(-48%, -48%);
    transform: scale(1.05) translate(-48%, -48%);
}

.phone {
    max-width: 1250px;
    margin: 0 auto;

}

.phone .box-hd {
    padding-top: 20px;
    padding-bottom: 20px;
}

.phone .list {
    margin-top: -1px;
    border-top-width: 12px;
    border-top-style: solid;
}

.box-bd {
    position: relative;
}

.more {
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    font-size: 1.3vw;
}
</style>